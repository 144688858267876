/* src/styles.css */

body,
html,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
}

.App {
  height: 100%;
  width: 100%;
}

canvas {
  display: block;
  height: 100% !important;
  width: 100% !important;
}
